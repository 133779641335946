<template>
  <LSection>
    <template #body>
      <div class="flex flex-col items-center justify-center mb-16">
        <div class="w-full">
          <TabNavigation :tabs="tabs" />
          <div class="body relative mb-12">
            <router-view name="impactTab"></router-view>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import { ref } from "vue";
import LSection from "@/components/layout/LSection.vue";
import TabNavigation from "@/components/TabNavigation.vue";

export default {
  name: "ChatbotTabs",
  components: { LSection, TabNavigation },
  setup() {
    const tabs = ref([
      {
        name: "About QT strategy chatbot",
        to: { name: "aboutChatbot" },
        routeName: "aboutChatbot",
      },
      {
        name: "Interact with quantum national quantum strategies",
        to: { name: "chatbotTab" },
        routeName: "chatbotTab",
      },
    ]);

    return {
      tabs,
    };
  },
};
</script>
